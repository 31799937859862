import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import Content from "../sections/about/Content";
import Team from "../sections/about/Team";
import CTA from "../sections/about/CTA";

const About = () => {
  return (
    <>
      <PageWrapper>
        <Hero title="About us">
          We are a team of experiened healthcare professionals and doctors, making world-class healthcare available to everyone
        </Hero>
        <Content 
          title="Our mission is to make healthcare accessible" 
          subtitle = "We believe everyone has the right to healthcare. Living in a different part of the world shouldn't limit the opportunity to feel better. Zahab works with leading hospitals and doctors in India to make healthcare available to people around the world"
          />
        {/* <Team />
        <CTA /> */}
      </PageWrapper>
    </>
  );
};
export default About;
